@import "style-functions";
@import "style-config";

/* Custom pages */
.custom {
  .cover {
    position: relative;
    text-align: center;

    img {
      display: block;
      width: 100%;
      max-width: 1366px;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .slide-bullets {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-content: center;
      justify-content: center;
      bottom: 20px;

      div {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        cursor: pointer;
        background-color: $color-highlight;
        border: solid 2px transparent;
        @include transition(all ease-in-out 0.2s);

        &:hover {
          background-color: $color-highlight-light;
          border-color: $color-highlight;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      .active-bullet {
        background-color: $color-highlight-light;
        border-color: $color-highlight;
      }
    }
  }
}

.home,
.history {
  .home-text,
  .history-text {
    max-width: 460px;
    margin: 70px auto;
    padding: 0 20px;
    text-align: center;
    line-height: 23px;

    h2 {
      color: $color-highlight;
      @include font-size(26px);
      padding-bottom: 40px;
    }

    p {
      padding: 8px 0;
      @include font-size(18px);
    }
  }

  .home-imgs,
  .history-imgs {
    max-width: 1000px;
    margin: 0 auto;

    @include mf-sm {
      display: flex;
      align-content: center;
      justify-content: center;
    }

    div {
      margin: 35px;

      @include mf-sm {
        margin: 0 20px;
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
    }
  }
}

.home .home-text {
  p:last-child {
    @include font-size(16px);
  }
}
.history .history-text {
  max-width: 520px;
}
